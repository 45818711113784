<!--申请结算（运营端）-->
<template>
  <div class="courseUser">
    <div class="pageContol listWrap templateList">
      <div class="framePage">
        <div class="framePage-title">
          <span>
            <em>当前位置：</em>
            <a href="javascript:;">结算管理</a>
            <i>></i>
            <a href="javascript:;" @click="init" class="cur-a">结算申请</a>
          </span>
        </div>
        <div class="framePage-body">
          <div class="operationControl" style="display: flex;flex-direction: column;align-items: flex-start;">
            <div class="searchbox" style="margin-bottom: 15px">
              <div title="班级编码" class="searchboxItem ci-full">
                <span class="itemLabel">班级编码:</span>
                <el-input
                  v-model="selectData.projectCode"
                  type="text"
                  size="small"
                  clearable
                  placeholder="请输入班级编码"
                />
              </div>
              <div title="班级名称" class="searchboxItem ci-full">
                <span class="itemLabel">班级名称:</span>
                <el-input
                  v-model="selectData.projectName"
                  type="text"
                  size="small"
                  clearable
                  placeholder="请输入班级名称"
                />
              </div>
              <div title="所属机构:" class="searchboxItem ci-full">
                <span class="itemLabel">所属机构:</span>
                <el-select
                  size="small"
                  v-model="selectData.compId"
                  remote
                  :remote-method="getCompanyList"
                  filterable
                  clearable
                  placeholder="请至少输入两个字搜索"
                  ><el-option
                    v-for="item in CompanyList"
                    :key="item.compId"
                    :label="item.compName"
                    :value="item.compId"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div class="searchbox">
              <div title="行政区划" class="searchboxItem ci-full">
                <span class="itemLabel">行政区划:</span>
                <el-cascader
                  v-model="selectData.areaId"
                  :options="areatreeList"
                  :props="propsarea"
                  :disabled="disabled"
                  clearable
                  filterable
                  size="small"
                ></el-cascader>
              </div>
              <div title="培训类型" class="searchboxItem ci-full">
                <span class="itemLabel">培训类型:</span>
                <el-cascader
                  :props="{
                    emitPath: false,
                    value: 'id',
                    label: 'label',
                    children: 'children',
                    checkStrictly: true,
                  }"
                  v-model="selectData.trainTypeId"
                  size="small"
                  clearable
                  :options="trainTypeList"
                  @change="handleTrainType"
                ></el-cascader>
              </div>
              <div title="班级状态:" class="searchboxItem ci-full">
                <span class="itemLabel">班级状态:</span>
                <el-select
                  size="small"
                  v-model="selectData.projectState"
                  placeholder="请选择班级状态"
                  clearable
                >
                  <el-option
                    v-for="item in projectStateList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
              <div class="df">
                <el-button
                  type="primary"
                  class="bgc-bv"
                  round
                  @click="getData()"
                  >查询</el-button
                >
                <el-button
                  type="primary"
                  class="bgc-bv"
                  round
                  @click="batchSettleAccounts"
                  >申请结算</el-button
                >
              </div>
            </div>
          </div>
          <div class="framePage-scroll">
            <div class="ovy-a">
              <el-table
                ref="multipleTable"
                :data="tableData"
                :height="tableHeight"
                size="small"
                tooltip-effect="dark"
                style="width: 100%"
                :header-cell-style="tableHeader"
                :row-key="(row) => row.projectId"
                @selection-change="handleSelectionChange"
                stripe
              >
                <el-table-column
                  type="selection"
                  width="55"
                  :reserve-selection="true"
                  fixed
                  align="center"
                  label="多选"
                >
                </el-table-column>
                <el-table-column
                  label="班级编码"
                  align="left"
                  prop="projectCode"
                  show-overflow-tooltip
                  width="240"
                  fixed
                />
                <el-table-column
                  label="班级名称"
                  align="left"
                  show-overflow-tooltip
                  prop="projectName"
                  min-width="240"
                />
                <el-table-column
                  label="所属机构"
                  align="left"
                  show-overflow-tooltip
                  prop="compName"
                  min-width="240"
                />
                <el-table-column
                  label="行政区划"
                  align="left"
                  show-overflow-tooltip
                  prop="areaNamePath"
                  min-width="200"
                />

                <el-table-column
                  label="培训类型"
                  align="left"
                  show-overflow-tooltip
                  prop="trainTypeNamePath"
                  min-width="150"
                />
                <el-table-column
                  label="班级状态"
                  align="left"
                  show-overflow-tooltip
                  prop="projectState"
                >
                  <template slot-scope="{ row }">
                    {{ $setDictionary("PROJECTSTATE", row.projectState) }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="注册人数"
                  align="right"
                  show-overflow-tooltip
                  prop="registerNum"
                />
                <el-table-column
                  label="结业人数"
                  align="right"
                  show-overflow-tooltip
                  prop="graduateNum"
                />
                <el-table-column
                  label="操作"
                  width="100px"
                  align="center"
                  fixed="right"
                >
                  <template slot-scope="{ row }">
                    <el-button
                      type="text"
                      size="mini"
                      style="padding: 0 5px"
                      @click="settleAccounts(row)"
                      >申请结算</el-button
                    >
                  </template>
                </el-table-column>
                <Empty slot="empty" />
              </el-table>
            </div>
          </div>
          <PageNum
            :apiData="apiData"
            @sizeChange="sizeChange"
            @getData="getData"
          />
          <div class="myBatch">
            <div class="BatchBox">
              <div class="BatchBoxFirst">
                <p>已选班级</p>
                <p>（{{ multipleSelection.length }}）</p>
              </div>
              <div class="BatchBoxSecond ovy-a">
                <div class="BatchforBox">
                  <div
                    v-for="(item, index) in multipleSelection"
                    :key="item.projectId"
                    class="BatchforFlex"
                  >
                    <div class="BatchImgBox" @click="deleteImg(item)">
                      <img
                        :src="require('@/assets/close_.png')"
                        alt=""
                        @mouseover="transToWhite(index, $event)"
                        @mouseleave="transToBlack(index, $event)"
                      />
                    </div>
                    <span style="padding-left: 5px">{{
                      item.projectName
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 申请结算弹框 -->
    <el-dialog
      title="结算申请"
      :visible.sync="loadingSettleAccounts.dialogVisible"
      width="80%"
      :before-close="closeSettleAccounts"
    >
      <el-form
        :model="loadingSettleAccounts"
        :rules="loadingSettleAccountsRules"
        ref="loadingSettleAccounts"
        label-width="100px"
        size="small"
        class="demo-ruleForm"
        ><el-form-item class="xzht" label="选择合同" prop="contractId">
          <el-select
            v-model="loadingSettleAccounts.contractId"
            clearable
            placeholder="请选择合同"
            @change="selectAgreemen"
          >
            <el-option
              v-for="(item, index) in loadingSettleAccounts.agreementData"
              :key="index"
              :label="item.contractName"
              :value="item.contractId"
            ></el-option>
          </el-select>
          <template v-if="loadingSettleAccounts.contractId">
            <el-button class="xzht_2 bgc-bv" @click="previewAgreement"
              >预览合同</el-button
            >
            <div class="xzht_3">
              合同有效期：{{ loadingSettleAccounts.agreementTime }}
            </div>
            <div class="xzht_5" v-if="loadingSettleAccounts.agreementTimeOld">有效</div>
            <div class="xzht_6" v-else>已过期</div>
          </template>
        </el-form-item>
        <el-form-item class="xzht_jsfs" v-if="loadingSettleAccounts.contractId">
          <div class="xzht_jsfs_1">
            结算标准: {{ loadingSettleAccounts.chargeMessage }}
          </div>
          <div class="xzht_jsfs_1">
            结算方式:
            {{
              $setDictionary(
                "CONTRACT_SETTLEMENT_METHOD",
                loadingSettleAccounts.settlementMethod
              )
            }}
            <!-- <el-radio-group
              v-model="loadingSettleAccounts.settlementMethod"
              :disabled="true"
            >
              <el-radio
                v-for="item in loadingSettleAccounts.settlementMethodList"
                :key="item.value"
                :label="item.value"
                >{{ item.label }}</el-radio
              >
            </el-radio-group> -->
          </div>
        </el-form-item>
        <el-form-item label="上传附件" style="margin-bottom: 70px;">
          <el-upload
            ref="fileupload"
            class="upload-demo upload-workers"
            action
            accept=".png,.jpg,.jpeg,.pdf"
            :on-change="handleChange"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :file-list="loadingSettleAccounts.fileList"
            :auto-upload="false"
            :show-file-list="true"
            :limit="10"
          ><el-button class="bgc-bv" slot="trigger" size="mini">上传文件</el-button>
            <span style="color:red;">请上传.png,.jpg,.jpeg,.pdf,.PDF等格式的文件，且最多10个文件！</span>
          </el-upload>
        </el-form-item>
        <el-form-item label="班级列表">
          <el-table
            ref="classesList"
            :data="loadingSettleAccounts.classesListData"
            size="small"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="tableHeader"
            ><el-table-column
              label="序号"
              align="center"
              type="index"
              :index="indexMethod"
              width="80px"
            ></el-table-column>
            <el-table-column
              label="班级编码"
              align="left"
              prop="projectCode"
              show-overflow-tooltip
              width="240"
            />
            <el-table-column
              label="班级名称"
              align="left"
              show-overflow-tooltip
              prop="projectName"
              min-width="240"
            />
            <el-table-column
              label="注册人数"
              align="right"
              show-overflow-tooltip
              prop="registerNum"
            />
            <el-table-column
              label="结业人数"
              align="right"
              show-overflow-tooltip
              prop="projectGraduateNum"
            />
            <el-table-column
              label="机构名称"
              align="left"
              show-overflow-tooltip
              prop="compName"
              min-width="240"
            />
          </el-table>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="bgc-bv" @click="closeSettleAccounts">取 消</el-button>
        <el-button class="bgc-bv" type="primary" @click="okSettleAccounts"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 预览合同 - loading -->
    <el-dialog
      title="预览合同"
      :visible.sync="dialogVisible3"
      width="900px"
      top="2%"
      center
      ><contractManagementAdd
        :seeTH="true"
        :seeContractId="loadingSettleAccounts.contractId"
        v-if="dialogVisible3"
      />
    </el-dialog>
    <!-- 预览结算上传的附件 -->
    <el-dialog
      title="预览附件"
      :visible.sync="seeFileData.dialogVisible4"
      width="800px"
      top="2%"
      center
      ><div class="ovy-a">
        <div
          v-if="seeFileData.fileType == 'pdf' || seeFileData.fileType == 'PDF'"
          id="pdf-cert1"
          style="height: 600px"
        ></div>
        <div v-else>
          <img :src="seeFileData.fileUrl" alt="" width="100%" height="100%" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import contractManagementAdd from "@/views/financialSettlement/contractManagementAdd";
import pdf from "pdfobject";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "applySettlementoperation",
  components: {
    Empty,
    PageNum,
    contractManagementAdd,
  },
  mixins: [List],
  data() {
    return {
      // 检索数据
      selectData: {
        areaId: "", //行政区划
        trainTypeId: "", //培训类型
        projectName: "", //班级名称
        projectCode: "", //班级编码
        compId: "", //所属机构
        projectState: "", //班级状态
      },
      areatreeList: [], //行政区划list
      trainTypeList: [], //培训类型list
      CompanyList: [], // 所属机构list
      projectStateList: [], //班级状态list
      //行政区划prop
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      multipleSelection: [], //列表选中list
      // 申请结算单 - 弹框数据
      loadingSettleAccounts: {
        // 弹框转态
        dialogVisible: false,
        // 当前选中合同id
        contractId: "",
        // 当前选中合同的数据
        contractStandard: {},
        // 当前选中合同显示时间
        agreementTime: "",
        // 当前选中合同是否过期
        agreementTimeOld: "",
        // 结算标准
        chargeMessage: "",
        // 结算方式
        settlementMethod: "",
        // 结算方式list
        settlementMethodList: [],
        // 合同列表
        agreementData: [],
        // 上传附件
        fileList:[],
        // 班级列表
        classesListData: [],
      },
      // 预览合同 - 组件显示与隐藏
      dialogVisible3: false,
      // 申请结算单 - 弹框数据校验
      loadingSettleAccountsRules: {
        contractId: [
          {
            required: true,
            message: "请选择合同",
            trigger: "change",
          },
        ],
      },
      // 预览结算上传的附件
      seeFileData:{
        dialogVisible4: false, // 预览的弹框
        fileType:"", // 预览文件的类型
        fileUrl:"", // 预览文件的路径
      }
    };
  },
  computed: {},
  created() {},
  methods: {
    // 初始化方法
    init() {
      this.getTableHeight(); //获取列表高度
      this.getareatree(); //获取行政区划
      this.getTraintype(); //获取培训类型
      this.getclassstatusList(); //班级状态list
      this.getData(); //获取列表
      this.getcontractSettlementMethod_type(); //获取结算方式字典
    },
    // 获取 -结算方式字典
    getcontractSettlementMethod_type() {
      // 类型
      let arr1 = this.$setDictionary("CONTRACT_SETTLEMENT_METHOD", "list");
      for (const key in arr1) {
        this.loadingSettleAccounts.settlementMethodList.push({
          label: arr1[key],
          value: key,
        });
      }
    },
    //两个字搜索 - 所属机构
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    //鼠标移入
    transToWhite(index, e) {
      e.target.src = require("@/assets/error.png");
    },
    //鼠标移出
    transToBlack(index, e) {
      e.target.src = require("@/assets/close_.png");
    },
    deleteImg(itemobj) {
      const SELECTION = this.$refs.multipleTable.selection;
      this.$refs.multipleTable.toggleRowSelection(
        SELECTION.find((e) => e.projectId === itemobj.projectId),
        false
      );
    },
    //获取行政区划
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    //获取培训类型
    getTraintype() {
      this.$post("/sys/category/train-type/tree").then((res) => {
        if (res.status == "0") {
          this.trainTypeList = res.data;
        }
      });
    },
    //班级状态list
    getclassstatusList() {
      const classstatuslist = this.$setDictionary("PROJECTSTATE", "list");
      const list = [];
      for (const key in classstatuslist) {
        list.push({
          value: key,
          label: classstatuslist[key],
        });
      }
      this.projectStateList = list.filter(
        (e) => e.value != "10" && e.value != "20" && e.value != "40"
      );
    },
    //获取列表
    getData(pageNum) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        ...this.selectData,
      };
      this.doFetch({
        url: "/biz/new/bill/operation/pageList",
        params,
        pageNum,
      });
    },
    //多选change
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //清空多选
    clearSelection() {
      this.multipleSelection = [];
      if (this.$refs.multipleTable) {
        this.$refs.multipleTable.clearSelection();
      }
    },
    //单个申请结算
    settleAccounts(row) {
      this.getSettleAccounts([row]);
    },
    //批量申请结算
    batchSettleAccounts() {
      if (this.multipleSelection.length == 0) {
        this.$message.error("请至少勾选一项！");
        return;
      }
      this.getSettleAccounts(this.multipleSelection);
    },
    //列表 - 申请结算接口
    getSettleAccounts(settlementArray) {
      let newSettlementArray = [];
      // 处理结算人数特殊字段
      settlementArray.map((e) => {
        newSettlementArray.push(e.projectId);
      });
      this.$post("/biz/new/bill/operation/generateStatement", {
        projectIds: newSettlementArray,
      })
        .then((res) => {
          if (res.data) {
            this.loadingSettleAccounts.agreementData = res.data.contractList;
            this.loadingSettleAccounts.classesListData = res.data.projectList;
            console.log(this.loadingSettleAccounts.classesListData);
            this.loadingSettleAccounts.dialogVisible = true;
          }
        })
        .catch(() => {
          return;
        });
    },
    //申请结算单弹框 - 选择合同
    selectAgreemen(e) {
      for (
        let i = 0;
        i < this.loadingSettleAccounts.agreementData.length;
        i++
      ) {
        if (this.loadingSettleAccounts.agreementData[i].contractId == e) {
          this.loadingSettleAccounts.agreementTime =
            this.loadingSettleAccounts.agreementData[i].startDate +
            "~" +
            this.loadingSettleAccounts.agreementData[i].endDate;
          this.loadingSettleAccounts.contractStandard =
            this.loadingSettleAccounts.agreementData[i];
          this.loadingSettleAccounts.settlementMethod =
            this.loadingSettleAccounts.agreementData[i].settlementMethod;
          
          // 判断合同是否过期 
          this.loadingSettleAccounts.agreementTimeOld = new Date(this.$moment(this.loadingSettleAccounts.agreementData[i].endDate).format("YYYY-MM-DD")).getTime() + 1000*60*60*24 > new Date().getTime()? true : false;
        }
      }
      this.selectAgreemen_jsbz(this.loadingSettleAccounts.contractStandard);
    },
    //申请结算单弹框 - 选择合同 - 变换当前选中合同的结算标准
    selectAgreemen_jsbz(lData) {
      let htData = JSON.parse(lData.settleContent);
      // settleStandardType = 10;技术/课程费用分开
      if (lData.settleStandardType == "10") {
        // charge = 10;按比例收费
        if (htData.charge == "10") {
          this.loadingSettleAccounts.chargeMessage =
            "技术/课程费用分开,按比例收费,技术比例" +
            htData.technologyRatio +
            "%,课程比例" +
            htData.coursesRatio +
            "%";
        }
        // charge = 20;按人数收费
        if (htData.charge == "20") {
          this.loadingSettleAccounts.chargeMessage =
            "技术/课程费用分开,按人数收费,技术每人收费" +
            htData.technologyCharge +
            "元,课程每人收" +
            htData.coursesCharge +
            "元";
        }
        // charge = 30;按课时收费
        if (htData.charge == "30") {
          this.loadingSettleAccounts.chargeMessage =
            "技术/课程费用分开,按课时收费,技术每人/课时收费" +
            htData.technologyChargePerson +
            "元,课程每人/课时收费" +
            htData.coursesChargePerson +
            "元";
        }
      }
      // settleStandardType = 20;技术/课程费用合计
      if (lData.settleStandardType == "20") {
        // charge = 10;按比例收费
        if (htData.charge == "10") {
          this.loadingSettleAccounts.chargeMessage =
            "技术/课程费用合计,按比例收费,比例" + htData.ratio + "%";
        }
        // charge = 20;按人数收费
        if (htData.charge == "20") {
          this.loadingSettleAccounts.chargeMessage =
            "技术/课程费用合计,按人数收费,每人收费" +
            htData.chargePerson +
            "元";
        }
        // charge = 30;按课时收费
        if (htData.charge == "30") {
          this.loadingSettleAccounts.chargeMessage =
            "技术/课程费用合计,按课时每人收费" + htData.chargeHourPerson + "元";
        }
        // charge = 40;按次数结算
        if (htData.charge == "40") {
          let message = "";
          for (var n = 0; n < htData.SettlementByTimeList.length; n++) {
            message +=
              "第" +
              (n + 1) +
              "次" +
              htData.SettlementByTimeList[n].SettlementByTime +
              "元/人;";
          }
          this.loadingSettleAccounts.chargeMessage = "按次数结算:" + message;
        }
      }
      // settleStandardType = 30;固定费用
      if (lData.settleStandardType == "30") {
        this.loadingSettleAccounts.chargeMessage =
          "固定费用" + htData.fixedCost + "元/年";
      }
      // settleStandardType = 40;其他
      if (lData.settleStandardType == "40") {
        this.loadingSettleAccounts.chargeMessage = "其他";
      }
      // 根据合同 - 处理结算人数根据不同的结算方式取值
      for (let i = 0;i < this.loadingSettleAccounts.classesListData.length;i++) {
        this.settleNumSource(this.loadingSettleAccounts.classesListData[i])
      }
    },
    // 申请结算单弹框 - 选择合同 - 结算人数根据不同的结算方式取值
    settleNumSource(item){
      if(this.loadingSettleAccounts.settlementMethod == '10') {//按注册人数
        item.settleNum = item.projectTotalNum
      } else if (this.loadingSettleAccounts.settlementMethod == '20') {//按入班人数
        item.settleNum = item.projectCurrentNum
      } else if (this.loadingSettleAccounts.settlementMethod == '30') {//按学习人数
        item.settleNum = item.studyNum
      } else if (this.loadingSettleAccounts.settlementMethod == '40') {//按完成人数
        item.settleNum = item.completeNum
      } else if (this.loadingSettleAccounts.settlementMethod == '50') {//按结业人数
        item.settleNum = item.graduateNum
      } else if (this.loadingSettleAccounts.settlementMethod == '60') {//按补贴人数
        item.settleNum = item.graduateNum
      } else if (!this.settlementMethod){
        item.settleNum = item.graduateNum
      }
    },
    //申请结算单弹框 - 预览合同
    previewAgreement() {
      this.dialogVisible3 = true;
    },
    //申请结算单弹框 - 上传附件
    handleChange(file,fileList) {
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isLt2M = file.size / 1024 / 1024 < 1;

      let uploadFiles = this.$refs['fileupload'].uploadFiles;
      let indexs = uploadFiles.indexOf(file);

      if (!isLt2M) {
        uploadFiles.splice(indexs,1);
        this.$message.error("上传图片大小不能超过 1MB!");
        return false;
      } else if (
        !".png,.jpg,.jpeg,.pdf,.PDF".includes(
          extension[extension.length - 1]
        )
      ) {
        uploadFiles.splice(indexs,1);
        this.$message.warning({
          message: "请上传.png,.jpg,.jpeg,.pdf,.PDF等格式的文件！",
          duration: 1000,
        });
        return false;
      } else {
        let formData = new FormData();
        formData.append("folder ", "COURSE_WARE");
        formData.append("file ", file.raw);
        formData.append("fileType ", extension);
        this.$Postformat("/sys/upload", formData)
          .then((res) => {
            this.loadingSettleAccounts.fileList.push({
              name:file.name,
              fileName:file.name,
              fileURL:res.data.fileURL,
              fileKey:res.data.fileKey,
              fileType: extension,
              docType: '99'
            })
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      }
    },
    //申请结算单弹框 - 删除照片
    handleRemove(file, fileList) {
      this.loadingSettleAccounts.fileList = fileList;
    },
    //申请结算单弹框 - 预览照片
    handlePreview(file) {
      console.log(file)
      this.seeFileData.dialogVisible4 = true;
      this.seeFileData.fileType = file.fileType;
      if (file.fileType == "pdf" || file.fileType == "PDF") {
        setTimeout(() => {
          pdf.embed(file.fileURL, "#pdf-cert1");
        }, 300);
      } else {
        this.seeFileData.fileUrl = file.fileURL;
      }
    },
    //申请结算单弹框 - 确定
    okSettleAccounts() {
      this.$refs["loadingSettleAccounts"].validate((valid) => {
        if (valid) {
          this.processingList()
          this.$post("/biz/new/bill/operation/applySettlement", {
            chargesSettlement: this.loadingSettleAccounts.chargeMessage,
            contractId: this.loadingSettleAccounts.contractId,
            fileList: this.loadingSettleAccounts.fileList,
            list: this.loadingSettleAccounts.classesListData,
            signCompId: this.loadingSettleAccounts.contractStandard.signCompId
          })
            .then((res) => {
              if (res.status == 0) {
                this.$message.success("申请结算成功！");
                this.clearSelection();
                this.getData(1);
                this.closeSettleAccounts();
              }
            })
            .catch(() => {
              return;
            });
        }
      });
    },
    // 处理班级列表数据 增加数据项
    processingList(){
      let htData = JSON.parse(this.loadingSettleAccounts.contractStandard.settleContent);
      console.log(this.loadingSettleAccounts.classesListData);
      this.loadingSettleAccounts.classesListData.forEach(item=>{
        // 10-10
        htData.technologyRatio && (item.technologyStandard = htData.technologyRatio) //技术
        htData.coursesRatio && (item.courseStandard = htData.coursesRatio) //课程
        // 10-20
        htData.technologyCharge && (item.technologyStandard = htData.technologyCharge) //技术
        htData.coursesCharge && (item.courseStandard = htData.coursesCharge) //课程
        // 10-30
        htData.technologyChargePerson && (item.technologyStandard = htData.technologyChargePerson) //技术
        htData.coursesChargePerson && (item.courseStandard = htData.coursesChargePerson) //课程
        
        htData.ratio && (item.chargeStandard = htData.ratio) //收费标准// 20-10
        htData.chargePerson && (item.chargeStandard = htData.chargePerson) //收费标准// 20-20
        htData.chargeHourPerson && (item.chargeStandard = htData.chargeHourPerson) //收费标准// 20-30
        if (this.loadingSettleAccounts.contractStandard.settleStandardType == "20"&&htData.charge == "40") {// 20-40
          for (var n = 0; n < htData.SettlementByTimeList.length; n++) {
            if (
              n == item.projectSettleNum
            ) {
              item.chargeStandard =
              htData.SettlementByTimeList[n].SettlementByTime;
            }
            }
        }
        // 国家补贴标准
        if (!item.countrySubsidyStandard) {
          item.countrySubsidyStandard = 0;
        }
        // 课程服务费（元）
        if (!item.courseFee) {
          item.courseFee = 0;
        }
        // 平台服务费（元）
        if (!item.platformTotalFee) {
          item.platformTotalFee = 0;
        }
        // 平台技术服务费（元）
        if (!item.platformTechnicalFee) {
          item.platformTechnicalFee = 0;
        }
        // 结算人数
        if (!item.settleNum) {
          item.settleNum = 0;
        }
        // 总课时
        if (!item.totalPeriod) {
          item.totalPeriod = 0;
        }
        // 公共课时
        if (!item.publicLessonNum) {
          item.publicLessonNum = 0;
        }
        // 隐藏字段 - 总费用
        if (!item.paycount) {
          item.paycount = 0;
        }
        // 平台结算金额（元）
        if (!item.platformSettlementAmount) {
          item.platformSettlementAmount = 0;
        }
        // 平台结算金额（元）- 二次结算金额
        if (!item.secondFee) {
          item.secondFee = 0;
        }
      })
    },
    //申请结算单弹框 - 关闭
    closeSettleAccounts() {
      // 将data中的某个数据重置到初始状态(赵金福-专用方法);
      this.loadingSettleAccounts = this.$options.data().loadingSettleAccounts;
      this.loadingSettleAccounts.dialogVisible = false;
    },
    //获取列表高度
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 5 + 3) * 16;
      if (opDom) {
        tHeight -= 0.675 * 16 + 1 + 40 + 15;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  mounted: function () {},
  beforeRouteLeave: resetKeepAlive,
};
</script>

<style lang="less" scoped>
.courseUser {
  height: 100%;
}
.el-textarea {
  resize: none;
  .el-textarea__inner {
    height: 200px;
  }
}
.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;
    &:before {
      content: "\e6df" !important;
    }
  }
  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    .el-tabs__content {
      flex: 1;
    }
  }
}
.btnBox {
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}
.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
.el-table th.is-left {
  > .cell {
    text-align: left;
  }
}
.el-table th.is-right {
  > .cell {
    text-align: right;
  }
}
.myBatch {
  padding: 1rem 1rem;
  width: 100%;
  .BatchBox {
    display: flex;
    height: 70px;
    border: 1px solid #ebeef5;
    border-radius: 5px;
    .BatchBoxFirst {
      width: 260px;
      background: #f2f7fd;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .BatchBoxSecond {
      width: 100%;
      .BatchforBox {
        display: flex;
        flex-wrap: wrap;
        .BatchforFlex {
          display: flex;
          align-items: center;
          padding: 5px 20px;
          cursor: pointer;
          .BatchImgBox {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          img {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
}
.xzht {
  /deep/ .el-form-item__content {
    display: flex;
  }
  .xzht_2 {
    margin: 0 10px;
  }
  .xzht_5 {
    color: #67C23A;
    margin-left: 10px;
  }
  .xzht_6 {
    margin-left: 10px;
    color: red;
  }
}
.xzht_jsfs {
  .xzht_jsfs_1 {
    color: red;
  }
}
.upload-workers {
  height: 30px;
  /deep/ .el-upload {
    height: 30px !important;
    width: 97px;
    border: none !important;  
    float: left;
    .el-button {
      padding: 5px 10px;
    }

  }
  /deep/ .el-upload-list {
      display: flex;
      flex-wrap: wrap;   /* 换行 */
      li {
        width: 20%;
      }
    }
}
</style>
